<template>
  <div>
    <nuxt-link to="/">
      <h1 class="text-lg dark:text-indigo-300">
        Owlie <span class="text-xs opacity-50">v0.1.0</span>
      </h1>
    </nuxt-link>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
